import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Telegraph from '../../assets/images/partners-img-2.svg';

const Partners = () => {
  const data = useStaticQuery(graphql`
    query {
      firstPartnerImg: file(relativePath: { eq: "partners-img-1.png" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
      thirdPartnerImg: file(relativePath: { eq: "partners-img-3.png" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
      fourthPartnerImg: file(relativePath: { eq: "partners-img-4.png" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  `);

  return (
    <section className="partners">
      <div className="container">
        <div className="partners__inner">
          <h2 className="partners__title">As seen in</h2>
          <ul className="partners__list">
            <li className="partners__item">
              <img
                src={data.firstPartnerImg.childImageSharp.original.src}
                alt="partner logo"
                className="partners__item-img"
              />
            </li>
            <li className="partners__item">
              <Telegraph className="partners__tlg-img" />
            </li>
            <li className="partners__item">
              <img
                src={data.thirdPartnerImg.childImageSharp.original.src}
                alt="partner logo"
                className="partners__item-img"
              />
            </li>
            <li className="partners__item partners__item--red">
              <img
                src={data.fourthPartnerImg.childImageSharp.original.src}
                alt="partner logo"
                className="partners__item-img"
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Partners;
