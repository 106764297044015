import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const UserFlow = () => {
  const data = useStaticQuery(graphql`
    query {
      landlordAva: file(relativePath: { eq: "userflow-landlord-ava.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 104, maxWidth: 104) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tenantAva: file(relativePath: { eq: "userflow-tenant-ava.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 104, maxWidth: 104) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const [isLandlord, setFlow] = useState(true);

  return (
    <section className="userflow">
      <div className="container">
        <div className="userflow__inner">
          <h2 className="userflow__title">How does it work?</h2>

          <div className="userflow__btns-wrap">
            <button
              className={
                isLandlord
                  ? 'userflow__btn userflow__btn--landlord-active'
                  : 'userflow__btn'
              }
              onClick={() => setFlow(true)}
            >
              Landlord Signup
            </button>
            <button
              className={
                !isLandlord
                  ? 'userflow__btn userflow__btn--tenant-active'
                  : 'userflow__btn'
              }
              onClick={() => setFlow(false)}
            >
              Tenants Signup
            </button>
          </div>

          <div
            className={
              isLandlord
                ? 'userflow__flow userflow__flow--landlord userflow__flow--active'
                : 'userflow__flow userflow__flow--landlord'
            }
          >
            <div className="userflow__user userflow__user--landlord">
              <div className="userflow__user-avatar">
                <Img fluid={data.landlordAva.childImageSharp.fluid} />
              </div>
              <span className="userflow__user-value">Landlord Signup</span>
            </div>
            <ul className="userflow__flow-list userflow__flow-list--landlord">
              <li className="userflow__flow-item">
                <div className="userflow__flow-count">1</div>
                <p className="userflow__flow-desc">Add your properties</p>
              </li>
              <li className="userflow__flow-item">
                <div className="userflow__flow-count">2</div>
                <p className="userflow__flow-desc">See tenants that match</p>
              </li>
              <li className="userflow__flow-item">
                <div className="userflow__flow-count">3</div>
                <p className="userflow__flow-desc">Message tenants directly</p>
              </li>
              <li className="userflow__flow-item">
                <div className="userflow__flow-count">4</div>
                <p className="userflow__flow-desc">The rest is up to you</p>
              </li>
            </ul>
          </div>

          <div
            className={
              !isLandlord
                ? 'userflow__flow userflow__flow--tenant userflow__flow--active'
                : 'userflow__flow userflow__flow--tenant'
            }
          >
            <div className="userflow__user userflow__user--tenant">
              <div className="userflow__user-avatar">
                <Img fluid={data.tenantAva.childImageSharp.fluid} />
              </div>
              <span className="userflow__user-value">Tenant Signup</span>
            </div>
            <ul className="userflow__flow-list userflow__flow-list--tenant">
              <li className="userflow__flow-item">
                <div className="userflow__flow-count">1</div>
                <p className="userflow__flow-desc">Add what you are looking for</p>
              </li>
              <li className="userflow__flow-item">
                <div className="userflow__flow-count">2</div>
                <p className="userflow__flow-desc">See properties that match </p>
              </li>
              <li className="userflow__flow-item">
                <div className="userflow__flow-count">3</div>
                <p className="userflow__flow-desc">Message landlords directly</p>
              </li>
              <li className="userflow__flow-item">
                <div className="userflow__flow-count">4</div>
                <p className="userflow__flow-desc">The rest is up to you</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserFlow;
