import React from 'react';

// import Cookies from '../components/Cookies';
import Feedback from '../components/Feedback';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Partners from '../components/Partners';
import Screens from '../components/Screens';
import SEO from '../components/seo';
import UserFlow from '../components/UserFlow';

import '../assets/styles/core.scss';

const IndexPage = () => (
  <>
    <Header />
    <SEO
      title="Pad - Connecting tenants and landlords"
      description="Top rated app that helps tenants and landlords to rent quicker, easier and more cost-effectively"
    />
    <Screens />
    <UserFlow />
    <Partners />
    <Feedback />
    <Footer hideSocial={true} />
    {/* <Cookies /> */}
  </>
);

export default IndexPage;
