import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import FeedbackSlider from '../Feedback-slider';

const Feedback = () => {
  const data = useStaticQuery(graphql`
    query {
      landlordFirstAva: file(relativePath: { eq: "feedback-user-1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 140, maxWidth: 140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      landlordSecondAva: file(relativePath: { eq: "feedback-user-2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 140, maxWidth: 140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tenantFirstAva: file(relativePath: { eq: "feedback-user-3.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 140, maxWidth: 140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tenantSecondAva: file(relativePath: { eq: "feedback-user-4.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 140, maxWidth: 140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const landlordsItems = [
    {
      img: data.landlordFirstAva.childImageSharp.fluid,
      text:
        'Being able to reach out proactively to potential tenants was amazing. It definitely saved me money.',
      author: 'Leslie, London',
    },
    {
      img: data.landlordSecondAva.childImageSharp.fluid,
      text: 'I arranged everything on chat. It was super easy.',
      author: 'Ben, Nottingham',
    },
  ];

  const tenantsItems = [
    {
      img: data.tenantFirstAva.childImageSharp.fluid,
      text:
        'Seeing some information about my landlord before going to the property made me feel more comfortable about renting.',
      author: 'Andy, Reading',
    },
    {
      img: data.tenantSecondAva.childImageSharp.fluid,
      text: `I was able to find a tenant who I thought I'd get on well with. It was great!`,
      author: 'David, Kent',
    },
  ];

  return (
    <section className="feedback">
      <div className="container">
        <div className="feedback__inner">
          <div className="feedback__landlords">
            <h2 className="feedback__title">Landlord</h2>
            <FeedbackSlider feedbackItems={landlordsItems} />
          </div>
          <div className="feedback__tenants">
            <h2 className="feedback__title">Tenants</h2>
            <FeedbackSlider feedbackItems={tenantsItems} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feedback;
