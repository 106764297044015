import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import SpotImgMob from '../../assets/images/screens-spot-mobile.svg';
import SpotImg from '../../assets/images/screens-spot.svg';

const Screens = () => {
  const data = useStaticQuery(graphql`
    query {
      firstScreenDesktopImage: file(relativePath: { eq: "screens-img-1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 749, maxWidth: 362) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstScreenMobileImage: file(relativePath: { eq: "screens-img-1-mobile.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 563, maxWidth: 274) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      secondScreenDesktopImage: file(relativePath: { eq: "screens-img-2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 750, maxWidth: 363) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      secondScreenMobileImage: file(relativePath: { eq: "screens-img-2-mobile.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 563, maxWidth: 274) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      thirdScreenDesktopImage: file(relativePath: { eq: "screens-img-3.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 745, maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      thirdScreenMobileImage: file(relativePath: { eq: "screens-img-3-mobile.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 564, maxWidth: 274) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const source = [
    [
      data.firstScreenDesktopImage.childImageSharp.fluid,
      {
        ...data.firstScreenMobileImage.childImageSharp.fluid,
        media: `(max-width: 767px)`,
      },
    ],
    [
      data.secondScreenDesktopImage.childImageSharp.fluid,
      {
        ...data.secondScreenMobileImage.childImageSharp.fluid,
        media: `(max-width: 767px)`,
      },
    ],
    [
      data.thirdScreenDesktopImage.childImageSharp.fluid,
      {
        ...data.thirdScreenMobileImage.childImageSharp.fluid,
        media: `(max-width: 767px)`,
      },
    ],
  ];

  return (
    <section className="screens">
      <h2 className="visually-hidden">Pad app screens</h2>
      <div className="container">
        <div className="screens__inner">
          <ul className="screens__list">
            <li
              className="screens__item"
              data-sal="slide-up"
              data-sal-duration="600"
              data-sal-delay="0"
              data-sal-easing="ease"
            >
              <div className="screens__circle-mobile" />
              <div className="screens__item-description">
                <h3 className="screens__item-title">It’s simple</h3>
                <p className="screens__item-text">
                  “When you arrange to meet a friend, you just send them a message. Why
                  should arranging property viewings be any different?”
                </p>
              </div>
              <div className="screens__item-img">
                <Img fluid={source[0]} style={{ overflow: 'visible' }} />
              </div>
            </li>
            <li
              className="screens__item"
              data-sal="slide-up"
              data-sal-duration="600"
              data-sal-delay="0"
              data-sal-easing="ease"
            >
              <SpotImgMob className="screens__spot-img-mobile" />
              <SpotImg className="screens__spot-img" />
              <div className="screens__item-description">
                <h3 className="screens__item-title">Start a conversation</h3>
                <p className="screens__item-text">
                  “We know everyone is different, so we’ve decided to leave organizing
                  viewings, contracts and deposits up to you. We just help with an
                  introduction. I guess it’s a bit like a dating app for tenants and
                  landlords...”
                </p>
              </div>
              <div className="screens__item-img">
                <Img fluid={source[1]} style={{ overflow: 'visible' }} />
              </div>
            </li>
            <li
              className="screens__item"
              data-sal="slide-up"
              data-sal-duration="600"
              data-sal-delay="0"
              data-sal-easing="ease"
            >
              <div className="screens__circle" />
              <div className="screens__item-description">
                <h3 className="screens__item-title">Renting, Sorted</h3>
                <p className="screens__item-text">
                  “Landlords, properties and tenants are sorted by relevance and recent
                  activity, so the more active and appropriate you are, the more matches
                  you get.”
                </p>
              </div>
              <div className="screens__item-img">
                <Img fluid={source[2]} style={{ overflow: 'visible' }} />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Screens;
