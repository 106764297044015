import React, { Component } from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';

import NextArrow from '../../assets/images/feedback-next-arrow.svg';
import PrevArrow from '../../assets/images/feedback-prev-arrow.svg';
import Quotes from '../../assets/images/feedback-quotes.svg';

export default class FeedbackSlider extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const { feedbackItems } = this.props;
    const settings = {
      className: 'feedback__slider',
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="feedback__slider-wrap">
        <Slider ref={c => (this.slider = c)} {...settings}>
          {feedbackItems.map((item, index) => (
            <div className="feedback__slide" key={`${index}SI`}>
              <div className="feedback__slide-avatar-wrap">
                <Quotes className="feedback__slide-quotes" />
                <div className="feedback__slide-avatar">
                  <Img fluid={item.img} />
                </div>
              </div>
              <div className="feedback__slide-desc">
                <p className="feedback__text">{item.text}</p>
                <p className="feedback__author">{item.author}</p>
              </div>
            </div>
          ))}
        </Slider>
        <div className="feedback__controls">
          <button
            className="feedback__button feedback__button--prev"
            onClick={this.previous}
          >
            <PrevArrow className="feedback__arrow" />
          </button>
          <button className="feedback__button feedback__button--next" onClick={this.next}>
            <NextArrow className="feedback__arrow" />
          </button>
        </div>
      </div>
    );
  }
}
